/**
 * START => Loading JQuery
 * * Loading 結構 👉 <div class="loader" style="height: 0px;"><img src=""></div>
 * * 顯示 👉 $('.loader').loader('show')
 * * 隱藏 👉 $('.loader').loader('hide')
 */
(function ($) {
    $.fn.loader = function (action) {
        if (action === "show") {
            this.css("height", "100vh");
            this.children().show();
        }
        if (action === "hide") {
            this.css("height", 0);
            setTimeout(() => this.children().hide(), 200);
        }
    };
}(jQuery));
/** END */


/**
 * START => form.serializeArray() 將相同 name 的 data 組再一起
 */
(function ($) {
    $.fn.formToObject = function () {
        var data = $(this).serializeArray();
        let result = {}
        data.forEach((column) => {
            if (!result[column.name]) {
                result[column.name] = column.value
            } else {
                if (!Array.isArray(result[column.name])) {
                    result[column.name] = [result[column.name], column.value]
                } else {
                    result[column.name] = [...result[column.name], column.value]
                }
            }
        })
        return result;
    };
})(jQuery);
/** END */

/**
 * Element Value 移除特殊符號
 * @param  {} target DOM Element
 */
function removeSpecialCharacter(target) {
    // target.value = target.value.replace(/[^\w\s]/gi, '');
    target.value = target.value.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
    );
}


/**
 * 頁面跳轉
 * @param  {} pageurl 跳轉頁面檔名+副檔名
 */
function gotolocation(pageurl) {
    location.href = pageurl;
}


/**
 * 頁面跳轉
 * @param  {} pageurl 跳轉頁面檔名+副檔名
 * @param  {} dataObj 要使用的參數數值 {key:value}
 */
function gotoMergeLocation(pageurl, dataObj) {
    let param = "";
    for (const key in dataObj) {
        if (dataObj[key] != null) {
            param += `${key}=${dataObj[key]}&`;
        }
    }
    param = param.trim().slice(0, -1);
    let url = pageurl + "?" + param;
    location.href = url;
}

/**
 * 回傳網址傳送的指定參數值
 * @param  {} valueName 參數名稱
 */
function getQueryString(valueName) {
    var url = window.location.href;
    if (url.indexOf("?") != -1) {
        const ary1 = url.split("?")[1].split("&");
        const aryName = ary1.map((x) => x.split("=")[0]);
        const aryValue = ary1.map((x) => x.split("=")[1]);
        const index = aryName.indexOf(valueName);
        const result = aryValue[index];
        return result;
    }
}
/**
 * localstorage僅保留特定key
 * @param  {string} savekey 使用","區隔多參數
 */
function cleanKeywithout(savekey) {
    var arr_savekey = savekey.split(",");
    var keys = Object.keys(localStorage),
        i = keys.length;
    while (i--) {
        var isBool = false;
        for (var j = 0; j < arr_savekey.length; j++) {
            if (keys[i] == arr_savekey[j]) {
                isBool = true;
            }
        }
        if (!isBool) {
            localStorage.removeItem(keys[i]);
        }
    }
}

/**
 * 儲存有期限的localstorage
 * *需與 timeCountdown(key) 一起使用
 * @param  {} key
 * @param  {} value
 * @param  {} expire
 */
function storeExpire(key, value, expire) {
    let obj = {
        time: new Date().getTime(),
        value: value,
        expire: expire,
    };
    let objStr = JSON.stringify(obj);
    localStorage.setItem(key, objStr);
    timeCountdown(key);
}

/**
 * 倒數移除localstorage
 * @param  {} key
 */
function timeCountdown(key) {
    var timer = setInterval(function () {
        if (localStorage.getItem(key)) {
            var name = localStorage.getItem(key);
            var nameObj = JSON.parse(name);
            if (new Date().getTime() - nameObj.time >= nameObj.expire) {
                localStorage.removeItem(key);
            }
        } else {
            clearInterval(timer);
        }
    }, 1000);
    return timer;
}

/**
 * 產生亂數
 */
function generateRandom() {
    return (r = Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111);
}

/**
 * 驗證二個參數不管大小寫和空格，數值是否相同
 * @param  {string} string1 參數1
 * @param  {string} string2 參數2
 * @returns {boolean} 驗證是否成功
 */
function ValidCaptchaTwoValue(string1, string2) {
    var str1 = removeSpaces(string1);
    var str2 = removeSpaces(string2);
    if (str1.toUpperCase() == str2.toUpperCase()) {
        return true;
    } else {
        return false;
    }
}

/**
 * 移除字串中所有空格
 * @param  {string} string
 */
function removeSpaces(string) {
    if (typeof string === "string" || string instanceof String) {
        return string.split(" ").join("");
    }
}


/**
 * 選擇器中，有相同 Attr 的元素只取一個
 */
function getUniqueName(selector, attr) {
    const inps = document.querySelectorAll(selector);
    let unique = [];
    for (var i = 0; i < inps.length; i++) {
        if (!i || inps[i - 1].getAttribute(attr) != inps[i].getAttribute(attr))
            unique.push(inps[i]);
    }
    return unique;
}

/**
 * 檢查字串是否為空值
 * @param  { Object } objInput
 * @param {string} objInput.value
 * @param {string} objInput.emptyData
 */
function checkDataIsEmpty(objInput) {
    let objData = {
        value: "",
        emptyData: "",
    };

    Object.keys(objInput).forEach(function (key) {
        if (key in objInput) {
            objData[key] = objInput[key];
        }
    });

    if (objData.value == objData.emptyData) {
        return true;
    }
    return false;
}

/**
 * 顯示或隱藏密碼
 * @param  { string } btn_el_Id 控制顯示/隱藏的按鈕
 * @param {string} password_el_id 密碼欄位
 * @param {string} toggleclass 切換的 className
 */
function showHidePassword(btn_el_Id, password_el_id, toggleclass) {
    const togglePassword = document.querySelector(btn_el_Id);
    const password = document.querySelector(password_el_id);

    togglePassword.addEventListener("click", function () {
        const type =
            password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        this.classList.toggle(`${toggleclass ? toggleclass : "fa-eye"}`);
    });
}

/**
 * 限制上傳檔案數量
 * @param {string} event
 * @param {number} limitNum 限制數量
 */
function limitFilesNum(event, limitNum = 0) {
    var $fileUpload = $(event.target);
    if (limitNum !== 0 && parseInt($fileUpload.get(0).files.length) > limitNum) {
        $fileUpload.addClass("is-invalid");
        $(`#${$fileUpload.attr("id")}_feedback`).show();
        $(`#${$fileUpload.attr("id")}_feedback`).text(
            $fileUpload.attr("data-msg-limit")
        );
    } else {
        $fileUpload.removeClass("is-invalid");
        $(`#${$fileUpload.attr("id")}_feedback`).hide();
    }
}

/**
 * START => Button：刪除自己父層
 */
function deleteItself(event, parentSelector) {
    const $target = $(event.target);
    $target.parents(parentSelector).remove();
}
/** END => Button：刪除自己父層 */

/**
 * 毫秒數轉換成時和分
 * @param {*} num
 * @returns
 */
function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds
    )}`;
}

/**
 * 加日期
 * @param {*} currentDate
 * @param {*} daysToAdd
 * @returns
 */
function addDaysToDate(currentDate, daysToAdd) {
    daysToAdd = daysToAdd || 0;

    // Instantiate a new object based on the current Date
    const futureDate = new Date(currentDate);

    // Adding the number of days
    futureDate.setDate(futureDate.getDate() + daysToAdd);

    return futureDate;
}

/**
 * 二個日期相差天數
 * @param {String} dateStr1
 * @param {String} dateStr2 
 * @returns {Number} Difference_In_Days 相差天數  
 */
function diffDatesDays(dateStr1, dateStr2) {
    let date1 = new Date(dateStr1);
    let date2 = new Date(dateStr2);
    let Difference_In_Time =
        date2.getTime() - date1.getTime();
    let Difference_In_Days =
        Math.round(Difference_In_Time / (1000 * 3600 * 24));
    return Difference_In_Days
}

/**
 * 頁面切換
 * * pageName 為 data-page 的 value
 * * setBackPage 為選填，如需要動態跳頁的話可搭配使用
 */
function tran(pageName, setBackPage = "") {
    if (setBackPage !== "") {
        $('.wiper[role="wiper-control"]').attr("data-back_page", setBackPage);
    }
    $("div[data-page=" + pageName + "]")
        .removeClass("closed")
        .addClass("open");
    $("div.open[data-page!=" + pageName + "]")
        .removeClass("open")
        .addClass("closed");
}


/**
 * 日期格式轉換 yyyy-mm-dd
 * @param {*} date 
 * @returns 
 */
function formatDate(date, sperate = '-') {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join(sperate);
}

/**
 * 身分證驗證
 * @param {*} id 
 * @returns 
 */
function verifyId(id) {
    try {
        id = id.trim();
        let verification = id.match("^[A-Z][12]\\d{8}$")
        if (!verification) {
            return false
        }

        let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
        let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]

        id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

        let checkSum = 0
        for (let i = 0; i < id.length; i++) {
            let c = parseInt(id[i])
            let w = weights[i]
            checkSum += c * w
        }

        return checkSum % 10 == 0
    } catch (error) {
        return false
    }
}

/**
 * 取得檔名
 * @param {string} fullPath
 * @return {string} 
 */
function getFileName(fullPath) {
    if (!fullPath) {
        return ''
    }
    var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
    var filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
    }
    return filename
}


/**
 * 取得副檔名
 * @param {string} filename 
 * @returns string
 */
function getFileExtension(filename) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
}

/**
 * 異步讀取 input file
 * @param {event} e
 * @param {function} finishedCallback
 */
function readInChunks(e, finishedCallback) {
    let file = e.target.files[0]
    let CHUNK_SIZE = 512 * 1024; // 512KB
    let offset = 0;
    let reader = new FileReader();

    reader.onload = function (event) {
        if (event.target.result.length > 0) {
            offset += CHUNK_SIZE;
            readNext();
        } else {
            finishedCallback();
        }
    };

    function readNext() {
        let slice = file.slice(offset, offset + CHUNK_SIZE);
        reader.readAsText(slice);
    }
    readNext();
}

/**
 * 金額千位數逗號
 * @param {number} num
 * @return {number} 
 */
function numberComma(num) {
    num = +num;
    let comma = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(comma, ',');
}

/**
 * VIEWERJS：單一相片 Light box 效果
 * @param {string} id 
 * @param {Object} option 
 */
function createViewerJS(id, option = {}) {
    let config = {
        button: false,
        toolbar: false,
        fullscreen: false,
        loop: false,
        movable: false,
        rotatable: false,
        tooltip: false,
        scalable: false,
        ...option
    }
    new Viewer(document.getElementById(id), config);
}

/**
 * tableRender(table, data) 👉 更新 datatables
 * @param { table } table  datatables 元件
 * @param { object } data 要更新的 data
 */
function tableRender(table, data) {
    table.clear();
    table.rows.add(data);
    table.draw(false);
}

/**
 * 預覽圖檔
 * @param { event } event Event 事件
 * @param { nodeElement } fileLabel 顯示檔名的 Node Element
 * @param { nodeElement} fileWrap 包圖片的 DOM
 */
function handleFile(event, fileLabel, fileWrap = null, styleObj = {}) {
    let files = event.target.files
    if (fileWrap) {
        fileWrap.innerHTML = "";
    }
    if (!files.length) {
        fileLabel.textContent = "選擇檔案";
    } else {
        let extension = getFileExtension(files[0].name);
        if (extension !== 'pdf' && fileWrap) {
            const img = document.createElement("img");
            img.src = URL.createObjectURL(files[0]);
            if (Object.keys(styleObj).length > 0) {
                Object.entries(styleObj).forEach(([key, value]) => {
                    img.style[key] = value
                });
            } else {
                img.style.height = '100px';
            }
            img.onload = () => {
                URL.revokeObjectURL(img.src);
            };
            fileWrap.prepend(img);
        }
        fileLabel.textContent = files[0].name;
    }
}

/**
 * 建立 NodeElement by String
 * @param { string } htmlString 
 * @returns nodeElement
 */
function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
}

/**
 * 使用 JavaScript 產生 UUID
 * @return { string } 
 */
function _uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
    });
}

/**
 * 畫面上顯示文件
 * @param {*} target 
 * @param {*} fileType 
 * @param {*} fileText 
 * @param {*} uuid 
 */
function addNewFile(target, fileType, fileText, uuid) {
    let iconClass = '';
    switch (fileType) {
        case 'pdf':
            iconClass = 'far fa-file-pdf text-danger'
            break;
        case 'doc':
        case 'docx':
            iconClass = 'far fa-file-word text-standard'
            break;
        case 'jpg':
        case 'jpeg':
        case 'png':
            iconClass = 'far fa-file-image text-success'
            break;
        default:
            iconClass = 'far fa-file text-standard'
            break;
    }
    $(target).append(`
        <li class='mb-1 js-file-wrap' data-uuid="${uuid}">
            <div class="border rounded d-inline-block">
                <div class="d-flex">
                    <span class="mx-2 py-1"><i class="${iconClass}"></i></span>
                    <span class="py-1 pr-1"><span>${fileText}</span></span>
                    <button type="button" class="btn btn-outline-danger border-0 rounded-left-0"
                        onclick="deleteFile(event)"><i class="fas fa-times"></i></button>
                </div>
            </div>
        </li>
    `)
}

/**
 * 開啟地點地圖
 * @param {Number} lat 緯度
 * @param {Number} lng 經度
 * @returns 
 */
function openMap(lat, lng) {
    if (!lat || !lng) {
        Toast.fire({
            icon: 'warning',
            title: '請先填寫 經緯度'
        })
        return;
    }
    window.open(`http://maps.google.com/maps?q=loc:${intToFloat(+lat, 14)},${intToFloat(+lng, 14)}`, '',
        'height=850,,innerHeight=850,width=765,innerWidth=765,top=150,left=150,toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no'
    );
}

/**
 * 數字補至小數點特定位數
 * @param { Number } num 
 * @param { Number } decPlaces 
 * @returns {String}
 */
function intToFloat(num, decPlaces) {
    return num.toFixed(decPlaces);
}

/**
 * 阻止 input type=number 超過最長長度
 * @param { Number } number 
 * @returns 
 */
function preventNumberLength(number, el) {
    if (el.value.length === +number) return false;
}

/**
 * 驗證數字欄位
 * @param { Number } number 
 * @param { HTMLElement } el 
 */
function validNumberInput(number, el) {
    if (/^0/.test(el.value)) {
        el.value = el.value.replace(/^0/, "")
    }
    if (el.value.length > +number) {
        el.value = el.value.slice(0, number);
    }
}