/**
 * START => DataTables 預設共用參數
 */
let table_config = {
  searching: false,
  processing: true,
  pagingType: 'full_numbers',
  pageLength: 20,
  language: {
    emptyTable: '無資料',
    loadingRecords: '無資料',
    paginate: {
      first: '第一頁',
      last: '最末頁',
      next: '下一頁',
      previous: '上一頁'
    },
    select: {
      rows: '%d 筆資料被選取'
    },
    info: "第 _START_ 至 _END_ 筆<br class='d-none d-md-block'>總共 _TOTAL_ 筆",
    infoEmpty: "0 筆資料",
    lengthMenu: `<select class="form-control p-1">
    <option value="10">10筆</option>
    <option value="20">20筆</option>
    <option value="50">50筆</option>
    <option value="100">100筆</option>
    </select> / 每頁`
  },
  dom: '<"top"B>t<"bottom d-flex justify-content-between flex-wrap"i<"d-flex align-items-end ml-auto"pl>><"clear">',
  lengthMenu: [
    [10, 20, 50, 100],
    [10, 20, 50, 100]
  ],
  drawCallback: function (oSettings) {
    var totalPages = this.api().page.info().pages;
    if (totalPages <= 1) {
      $(`#${$(this).attr('id')}_paginate`).hide();
    } else {
      $(`#${$(this).attr('id')}_paginate`).show();
    }
  },
  initComplete: function (settings, json) {
    $(this).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
  }
}
/** END */

/**
 * START => Flatpickr 預設共用參數
 */
const flatpickr_config = {
  allowInput: true,
  dateFormat: "Y-m-d",
  wrap: true,
  locale: 'zh_tw',
  monthSelectorType: 'static',
  disableMobile: true,
}
/** END */

/**
 * START => summernote 預設共用參數
 */
const summernote_config = {
  lang: "zh-TW",
  toolbar: [
    ['style', ['style']],
    ['font', ['bold', 'underline', 'clear']],
    ['fontname', ['fontname']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['table', ['table']],
    ['insert', ['link', 'picture', 'video']],
  ]
}
/** END */

/** 
 * START => SweetAlet2：預設確認 + 取消顏色
 */
const swalBtn = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger mx-1",
    cancelButton: "btn btn-secondary mx-1"
  },
  buttonsStyling: false
});
const swalBtnReverse = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success mx-1",
    cancelButton: "btn btn-disable mx-1"
  },
  buttonsStyling: false
});
/** END */

/** 
 * START => SweetAlet2：預設 Toast 設定
 */
var Toast = Swal.mixin({
  toast: true,
  position: 'top',
  width: 400,
  showConfirmButton: false,
  timer: 3000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

/** 
 * START => Form：若為 disabled 則 size 為 value 長度
 */
$(document).ready(function () {
  $("input:disabled").attr('size', function () {
    return $(this).val().length;
  })
});
/** END */

/** START => JQueryValidator：共用錯誤設定 */
let jqueryValidator_config = {
  onfocusout: false,
  onkeyup: false,
  focusInvalid: false,
  errorElement: "div",
  errorPlacement: function (error, element) {
    error.addClass("invalid-feedback");
    if ($(element).data("error-class")) {
      $(error).addClass($(element).data("error-class"))
    }

    if (element.prop("type") === "checkbox") {
      error.insertAfter(element.parents('.js-jqerror-wrap'));
    } else if (element.data("validator") === "flatpickr") {
      error.insertAfter(element.parent());
    } else if (element.data("jqerror-place") === "parent") {
      error.insertAfter(element.parent());
    } else if (element.data("jqerror-place") === "inside-parent") {
      error.appendTo(element.parent());
    } else {
      error.insertAfter(element);
    }
  },
  highlight: function (element, errorClass, validClass) {
    if ($(element).data("validator") === "flatpickr") {
      $(element).parent().addClass("is-invalid").removeClass("is-valid");
    }
    $(element).addClass("is-invalid").removeClass("is-valid");
  },
  unhighlight: function (element, errorClass, validClass) {
    if ($(element).data("validator") === "flatpickr") {
      $(element).parent().removeClass("is-invalid");
    }
    $(element).removeClass("is-invalid");
    // if ($(element).data("validator") === "flatpickr") {
    //   $(element).parent().addClass("is-valid").removeClass("is-invalid");
    // }
    // if ($(element).data("jqvalid") !== null) {
    //   $(element).addClass("is-valid").removeClass("is-invalid");
    // }
  },
}
/** END */



/**
 * START => 處理 ENTER 動作
 * @param {*} e 
 */
function handleEnter(e, enterCallback) {
  var key = e.keyCode || e.which;
  if (key == 13) {
    enterCallback(e);
  }
}